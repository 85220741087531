<template>
  <div class="pt-5"></div>
  <div class="text-center d-flex flex-column align-items-center justify-content-center" id="report_register">
    <div>
      <i class="ri-checkbox-circle-fill text-success" style="font-size: 6rem"></i>
    </div>
    <div class="fw-bold fs-3">Registrasi Akun berhasil</div>
    <p class="mb-4">
      Link aktivasi akun sudah dikirim ke email/Whatsapp Anda. Silahkan periksa
      email/Whatsapp Anda untuk melakukan pembayaran biaya pendaftaran
    </p>

    <button class="btn btn-light border border-success" @click="goPage('home')">
      <i class="ri-home-fill me-2"></i>Kembali Halaman Utama
    </button>
  </div>
</template>

<style>
#report_register {
  height: calc(100vh - 46px);
}
</style>

<script>
import { goPage } from "@/assets/javascript/Function";

export default {
  methods: {
    goPage,
  },
};
</script>
