<template>
  <section id="form_pendaftaran">
    <div class="mt-5"></div>
    <div class="text-center mb-3 text-danger fw-bold">
      Formulir Pendaftaran Jalur Mandiri
    </div>
    <div class="container">
      <div
        class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic"
      >
        Verifikasi Email / Whatsapp
      </div>
      <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
        <form action="">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                Email <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-7 mb-md-3 mb-2">
              <input
                type="text"
                name="nis"
                class="form-control"
                id="nis"
                value=""
                placeholder="Masukan alamat email anda"
              />
            </div>
            <div
              class="col-12 col-md-2 mb-3 d-flex d-md-block justify-content-end"
            >
              <button class="btn btn-primary d-flex">
                <i class="ri-arrow-right-line me-2"></i> Verifikasi
              </button>
            </div>
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                No Whatsapp<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-7 mb-3">
              <input
                type="text"
                name="nis"
                class="form-control"
                id="nis"
                value=""
                placeholder="Masukan no whatsapp anda yang aktif"
                required
              />
            </div>
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                No. Token Email
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-7 mb-3">
              <input
                type="text"
                name="nis"
                class="form-control"
                id="nis"
                value=""
                placeholder="Untuk verifikasi email, masukkan 6 digit token yang telah dikirim ke Email Saudara"
                required
              />
            </div>
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                No Token Whatsapp
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-7 mb-3">
              <input
                type="text"
                name="nis"
                class="form-control"
                id="nis"
                value=""
                placeholder="Untuk verifikasi Whatsapp, masukkan 6 digit token yang telah dikirim Whatsapp Saudara"
                required
              />
            </div>
          </div>
          <div class="text-end">
            <span class="me-2">Selanjutnya</span>

            <button
              class="btn btn-success rounded-circle py-2"
              @click="goPage('data_akun')"
            >
              <i class="ri-arrow-right-line"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { goPage } from "@/assets/javascript/Function";
import axios from "axios";

export default {
  methods: {
    goPage,
    Insert() {
      let formData = new FormData();
      formData.append("name", this.name);
      axios
        .post("http://localhost:3000/form", formData)
        .then((response) => {
          // if (response.data.status == true) {
          //   this.showToast(response.data.message);
          //   //load the table
          //   this.data_employee = "";
          //   this.getEmployee();
          // } else {
          //   this.showAlert(response.data.message);
          // }
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  beforeMount() {
    this.Insert();
  },
};
</script>

<style></style>
