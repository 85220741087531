<template>
  <section id="form_pendaftaran">
    <div class="mt-5"></div>
    <div class="text-center mb-3 text-danger fw-bold">
      Formulir Pendaftaran Jalur Mandiri
    </div>
    <div class="container">
      <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
        Data Akun
      </div>
      <div class="w-100 h-100 border rounded-bottom border-2 px-3 py-3 mb-5">
        <form @submit.prevent="saveData">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                Email <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-6 col-lg-7 mb-md-3 mb-2">
              <input type="email" name="nis" class="form-control" id="nis" placeholder="Masukan alamat email anda"
                v-model="currentRecord.email" />
            </div>
            <div class="col-12 col-md-3 col-lg-2 mb-md-3 mb-2 text-end">
              <button class="btn btn-danger">Verifikasi Email</button>
            </div>
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                No Whatsapp<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nis" class="form-control" id="nis" v-model="currentRecord.whatsapp"
                placeholder="Masukan no whatsapp anda yang aktif" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                Password <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-md-3 mb-2">
              <input type="password" name="nis" class="form-control" id="nis" value="" placeholder="" />
              <div class="text-secondary">
                password adalah kunci yang dipergunakan untuk login pendaftaran,
                bukan login email. Password bebas diisi sendiri dan wajib
                terdiri dari (minimal) 6 digit angka dan/atau kombinasi keduanya
              </div>
            </div>

            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                Konfirmasi Password<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="password" name="password2" class="form-control" id="password2" value="" placeholder=""
                required />
            </div>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
            <label class="form-check-label" for="flexCheckDefault">
              Ya, saya setuju bahwa seluruh data yang saya isikan dan/ atau
              unggah adalah benar, sah, legal dan/atau sesuai dengan keadaan
              dan/atau kenyataan.<span class="fw-bold">SAYA TIDAK AKAN MENGUBAH SETELAN SETELAH AKUN DIBUAT</span>
            </label>
          </div>
          <div class="text-end">
            <button class="btn btn-danger me-3" @click="goPage('form_pendaftaran')">
              Kembali
            </button>
            <button class="btn btn-primary">Register</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { goPage, base_url, AlertPopup, ShowLoading, ErrorConnectionTimeOut } from "@/assets/javascript/Function";
import axios from "axios";

export default {
  data() {
    return {
      api: "",
      currentRecord: {
        no_ktp: "",
        nama_lengkap: "",
        nama_ijazah: "",
        warga_negara: "",
        nisn: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        jenis_kelamin: "",
        nama_ibu_kandung: "",
        email: "",
        whatsapp: "",
        slug: "ustb",
      },
    };
  },
  methods: {
    goPage,
    insert() {
      ShowLoading();
      const formData = new FormData();
      formData.append("slug", "ustb");

      formData.append("url", "form_mandiri");
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "form_mandiri";
      } else {
        this.api = base_url + "form_mandiri";
      }
      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      for (let [key, value] of formData) {
        console.log(`${key}: ${value}`)
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            AlertPopup("success", response.data.message, "", 1500, false);
            goPage("report_register");
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });

    },
    saveData() {
      localStorage.setItem("data_diri", JSON.stringify(this.currentRecord));
      this.insert();
    },
    indexData() {
      if (localStorage.getItem("data_diri") !== null) {
        let data = JSON.parse(localStorage.getItem("data_diri"));
        this.currentRecord = Object.assign({}, data);
      }
    },
  },
  mounted() {
    this.indexData();
  },
};
</script>

<style></style>
