// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/home_mobile.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/img/home_tab.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/img/Home.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width:375px){#img_home[data-v-2ee58c4b]{width:100vw;height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:cover}}@media screen and (min-width:375px){#img_home[data-v-2ee58c4b]{width:100vw;height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-size:cover}}@media screen and (min-width:576px){#img_home[data-v-2ee58c4b]{height:100vh;width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:50%;background-size:cover}}@media screen and (min-width:768px){#img_home[data-v-2ee58c4b]{height:100vh;width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:50%;background-size:cover}}@media screen and (min-width:1024px){#img_home[data-v-2ee58c4b]{height:100vh;width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:50%;background-size:cover;background-repeat:no-repeat}}#sub-title[data-v-2ee58c4b]:after{position:absolute;content:\"\";background-color:red;width:30%;height:3px}.grecaptcha-badge[data-v-2ee58c4b]{visibility:hidden}.fade[data-v-2ee58c4b]{animation:fadeInAnimation-2ee58c4b 1.5s ease;animation-iteration-count:1;animation-fill-mode:forwards}@keyframes fadeInAnimation-2ee58c4b{0%{opacity:0;transform:translateY(-10vh)}to{opacity:1;transform:translate(0)}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
