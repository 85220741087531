<template>
  <NavbarDefault />
  <div class="pt-5"></div>
  <div id="img_pilih_jalur">
    <div
      class="h-100 d-flex flex-column flex-md-row justify-content-md-center justify-content-end align-items-center align-items-md-end pe-3 pb-5 px-3">
      <div class="">
        <button @click="goPage('form_pendaftaran')" class="btn btn-danger border-light mb-3 me-md-5"
          style="min-width: 250px">
          <div class="fs-5 d-flex justify-content-center fw-bold">
            <i class="ri-user-shared-fill me-3"></i>JALUR MANDIRI
          </div>
        </button>
      </div>
      <div class="">
        <button data-bs-toggle="modal" data-bs-target="#cek_scholarship" class="btn btn-danger border-light mb-3 me-md-5"
          style="min-width: 250px">
          <div class="fs-5 d-flex justify-content-center fw-bold">
            <i class="ri-user-star-line me-3"></i>JALUR SCHOLARSHIP
          </div>
        </button>
      </div>
      <div>
        <button class="btn btn-danger border-light mb-3 " style="min-width: 250px" data-bs-toggle="modal"
          data-bs-target="#isi_data">
          <div class="fs-5 d-flex justify-content-center fw-bold">
            <i class="ri-user-star-fill me-3"></i>JALUR KIP KULIAH
          </div>
        </button>
      </div>
    </div>
  </div>
  <form @submit.prevent="cek_data_from_rumah_aspirasi">
    <div class="modal fade" id="isi_data" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="label_data_karyawan">
              Masukan Data <span class="text-capitalize"></span>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body modal-body2">
            <label for="ktp" class="form-label fs-7">
              No Ktp
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="text" @keypress="NumberOnly" name="alamat" class="form-control" id="ktp"
                v-model="currentRecord.no_ktp" maxlength="16" required />
            </div>
            <label for="tanggal_lahir" class="form-label fs-7">
              Tanggal lahir
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="date" name="alamat" class="form-control" id="tanggal_lahir"
                v-model="currentRecord.tanggal_lahir" required />
            </div>
            <label for="whatsapp" class="form-label fs-7">
              No Handphone / Whatsapp
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="tel" name="alamat" class="form-control" id="whatsapp" v-model="currentRecord.whatsapp"
                @keypress="NumberOnly" required />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Batal
            </button>
            <button type="submit" class="btn btn-danger">Cek Data</button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <form @submit.prevent="cek_scholarship">
    <div class="modal fade" id="cek_scholarship" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="label_data_karyawan">
              Masukan Data <span class="text-capitalize"></span>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body modal-body2">

            <label for="ktp" class="form-label fs-7">
              Kode Scholarship
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="text" name="alamat" class="form-control" id="ktp" v-model="currentRecord.kode_scholarship"
                required />
            </div>
            <label for="nama_lengkap" class="form-label fs-7">
              Nama lengkap sesuai KTP
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="text" name="alamat" class="form-control" id="nama_lengkap" v-model="currentRecord.nama_lengkap"
                @keypress="CharOnly" required />
            </div>
            <label for="ktp" class="form-label fs-7">
              No Ktp
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="text" @keypress="NumberOnly" name="alamat" class="form-control" id="ktp"
                v-model="currentRecord.no_ktp" maxlength="16" required />
            </div>
            <label for="tanggal_lahir" class="form-label fs-7">
              Tanggal lahir
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="date" name="alamat" class="form-control" id="tanggal_lahir"
                v-model="currentRecord.tanggal_lahir" required />
            </div>
            <label for="whatsapp" class="form-label fs-7">
              No Handphone / Whatsapp
              <span class="text-danger">*</span>
            </label>
            <div class="col-12  mb-2">
              <input type="tel" name="alamat" class="form-control" id="whatsapp" v-model="currentRecord.whatsapp"
                @keypress="NumberOnly" required />
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Batal
            </button>
            <button type="submit" class="btn btn-danger">Cek Data</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style>
#img_pilih_jalur {
  height: calc(100vh - 46px);

  background-image: url("@/assets/img/PilihJalur.jpg");
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}
</style>

<script>
import NavbarDefault from "@/components/NavbarDefault.vue";
import { NumberOnly, AlertPopup, ErrorConnectionTimeOut, base_url, ShowLoading, CharOnly, AlertPopupGoToURL } from "@/assets/javascript/Function.js";
import axios from "axios";

export default {
  data() {
    return {
      currentRecord: {
        no_ktp: "",
        tanggal_lahir: "",
        whatsapp: "",
      },

      tanggal_max_lahir: "",
    }
  },
  methods: {
    NumberOnly,
    CharOnly,
    goPage(nama_page) {
      this.$router.push("/" + nama_page);
    },

    cek_data_from_rumah_aspirasi() {

      // console.log(Cookies.get('token2'));
      ShowLoading();
      const formData = new FormData();
      formData.append("slug", "ustb");

      if (this.currentRecord.whatsapp.slice(0, 1) == '0') {
        const currentWhatsapp = this.currentRecord.whatsapp.slice(1);
        this.currentRecord.whatsapp = '62' + currentWhatsapp;
      }

      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "cek_data_dari_ustb";
      } else {
        this.api = base_url + "cek_data_dari_ustb";
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            const urlString = window.location.href;
            const cleanString = urlString.split('?')[0];
            const url = new URL(cleanString);
            url.pathname = "/";
            const modifiedString = url.toString();

            const kode_unik = response.data.data.kode_unik;

            AlertPopupGoToURL("", "Data ditemukan silahkan lengkapi data untuk melengkapi data yang kurang", true, modifiedString + "form_lengkapi_data_kip?kode_unik=" + kode_unik);
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            if (this.currentRecord.whatsapp.slice(0, 2) == '62') {
              const currentWhatsapp = this.currentRecord.whatsapp.slice(2);
              this.currentRecord.whatsapp = '0' + currentWhatsapp;

            }
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });


    },
    cek_scholarship() {

      // console.log(Cookies.get('token2'));
      ShowLoading();
      const formData = new FormData();
      formData.append("slug", "ustb");

      if (this.currentRecord.whatsapp.slice(0, 1) == '0') {
        const currentWhatsapp = this.currentRecord.whatsapp.slice(1);
        this.currentRecord.whatsapp = '62' + currentWhatsapp;
      }

      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "cek_scholarship";
      } else {
        this.api = base_url + "cek_scholarship";
      }

      for (let [key, value] of formData) {
        console.log(`${key}: ${value}`)
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            const urlString = window.location.href;
            const cleanString = urlString.split('?')[0];
            const url = new URL(cleanString);
            url.pathname = "/";
            const modifiedString = url.toString();

            const kode_unik = response.data.data.kode_unik;

            AlertPopupGoToURL("", "Silahkan lengkapi data", true, modifiedString + "form_lengkapi_data_scholarship?kode_unik=" + kode_unik);
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
            if (this.currentRecord.whatsapp.slice(0, 2) == '62') {
              const currentWhatsapp = this.currentRecord.whatsapp.slice(2);
              this.currentRecord.whatsapp = '0' + currentWhatsapp;

            }
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });


    },

  },
  components: {
    NavbarDefault
  },
  mounted() {
    const badgeElement = document.querySelector('.grecaptcha-badge');
    // Check if the badge element exists before applying the style
    if (badgeElement) {
      badgeElement.style.visibility = 'hidden';
    }

    const dateNow = new Date();

    const year15 = new Date(dateNow.getFullYear() - 17, dateNow.getMonth(), dateNow.getDate());
    const year45 = new Date(dateNow.getFullYear() - 45, dateNow.getMonth(), dateNow.getDate());
    // set tanggal_sekarang to the updated date
    this.currentRecord.tanggal_lahir = year15.toISOString().slice(0, 10);
    this.tanggal_max_lahir = year45.toISOString().slice(0, 10);

  }
};
</script>
