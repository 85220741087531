import router from "@/router";
import Swal from "sweetalert2";

export const goPage = (text) => {
  // Do something here
  router.push("/" + text);
};

let base = "";
let base2 = "";

if (process.env.NODE_ENV === "development") {
  base = "https://s4-api.arthaskyline.com/";
  base2 = "https://s4-api.arthaskyline.com/";
} else {
  base = "https://s4-api.arthaskyline.com/";
  base2 = "https://s4-api.arthaskyline.com/";
}

export let base_url_header = base;
export let base_url = base2;

export let base_url_wilayah_indonesia =
  "https://sandbox.arthaskyline.com/api-wilayah-indonesia-master/";

export const AlertPopup = (
  icon,
  message,
  submessage,
  timer,
  showConfirmButton
) => {
  Swal.fire({
    icon: icon,
    title: message,
    text: submessage,
    timer: timer,
    showConfirmButton: showConfirmButton,
    allowEnterKey: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
};

export const AlertPopupConfirm = (icon, message, submessage) => {
  Swal.fire({
    icon: icon,
    title: message,
    text: submessage,
    showConfirmButton: true,
    allowEnterKey: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
};

export const AlertPopupEror = (message, submessage) => {
  Swal.fire({
    icon: "error",
    title: message,
    text: submessage,
  });
};

export const AlertPopupGoToURL = (
  message,
  submessage,
  showConfirmButton,
  url
) => {
  Swal.fire({
    title: message,
    text: submessage,
    showConfirmButton: showConfirmButton,
    allowEnterKey: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    icon: "success",
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.href = url;
    }
  });
};

export const ShowLoading = () => {
  Swal.fire({
    title: "",
    html: `
    
    <div class="loading-dots">
        <div class="dot bg-warning"></div>
        <div class="dot bg-primary"></div>
        <div class="dot bg-danger"></div>
        <div class="dot bg-success"></div>
    </div>


<style>
.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 0 4px;
    animation: fallingDots 1.2s infinite ease-in-out;
    animation-delay: 0.2s;
    /* Added initial delay before animation starts */
    animation-fill-mode: both;
    /* Added to maintain final state after animation */
}

.dot:nth-child(1) {
    animation-delay: 0s;
    /* Reset delay for the first dot */
}

.dot:nth-child(2) {
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.2s;
}

.dot:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes fallingDots {
    0% {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(-20px);
    }
}
</style>


`, // add html attribute if you want or remove
    allowOutsideClick: false,
    showConfirmButton: false,
    // didOpen: () => {
    //   Swal.showLoading();
    // },
  });
};

export const ShowLoading2 = () => {
  Swal.fire({
    title: "",
    html: `<img src="https://s4-api.arthaskyline.com/loading_dot.gif"  style="width: 1in; height: 1in; object-fit: cover; object-position: top; cursor: pointer;">
`, // add html attribute if you want or remove
    background: "red",
    // didOpen: () => {
    //   Swal.showLoading();
    // },
    customClass: {
      popup: "swal-popup",
      content: "swal-content",
      confirmButton: "swal-button",
    },
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      const swalPopup = Swal.getPopup();
      swalPopup.style.backgroundColor = "transparent";
      swalPopup.style.boxShadow = "none";
    },
  });
};

export const ErrorConnectionTimeOut = (error) => {
  if (error.code == "ECONNABORTED") {
    AlertPopup(
      "error",
      "Jaringan koneksi internet tidak stabil",
      "periksa jaringan internet Anda",
      0,
      true
    );
  } else if (error.code == "ERR_NETWORK") {
    AlertPopup(
      "error",
      "Koneksi Internet Tidak ada",
      "periksa jaringan internet Anda",
      0,
      true
    );
  } else {
    console.log(error);
  }
};

export const CloseLoading = () => {
  Swal.close();
};

export const DateNow = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const dateTime = today;
  return dateTime;
};
export const DayNow = () => {
  let weekday = [
    "Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jum'at",
    "Sabtu",
  ][new Date().getDay()];
  return weekday;
};
export const MonthNow = () => {
  let weekday = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "September",
    "Oktober",
    "November",
    "Desember",
  ][new Date().getMonth()];
  return weekday;
};

export const JustDateNow = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  return dd;
};
export const JustYearNow = () => {
  var today = new Date();
  var year = today.getFullYear();
  return year;
};

export const TimeNow = () => {
  var today = new Date();
  const time =
    ("0" + today.getHours()).substr(-2) +
    ":" +
    ("0" + today.getMinutes()).substr(-2);

  return time;
};

export const rupiah = (number) => {
  return (
    new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    })
      .format(number)
      .slice(0, -2) + "-"
  );
};

export function NumberOnly(event) {
  const key = event.key;
  // Allow backspace and space
  if (key === "Backspace" || key === " ") {
    return;
  }
  // Prevent input of non-numeric characters
  if (!/^\d$/.test(key)) {
    event.preventDefault();
  }
}
export function CharOnly(event) {
  const keyCode = event.keyCode;
  // Allow backspace and space
  if (keyCode === 8 || keyCode === 32) {
    return;
  }
  // Convert keyCode to the corresponding character
  const character = String.fromCharCode(keyCode);
  // Regular expression pattern to match allowed characters
  const pattern = /^[a-zA-Z,.'\s]*$/;
  // Check if the character matches the pattern
  if (!pattern.test(character)) {
    event.preventDefault();
  }
}

export const rupiah2 = (number) => {
  let number2 = parseInt(number);
  console.log(
    Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    })
      .format(number2)
      .slice(3)
  );
  return new (Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number2).slice)(3);
};

export const randomString = () => {
  let length = 10;
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
