// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/major.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".swal-popup[data-v-0dbbd140]{box-shadow:none!important}.swal-content[data-v-0dbbd140],.swal-popup[data-v-0dbbd140]{background:transparent!important}.swal-content[data-v-0dbbd140]{border:none!important}.swal-button[data-v-0dbbd140]{display:none!important}@media screen and (max-width:375px){.letter-spacing[data-v-0dbbd140]{letter-spacing:.1em}}@media screen and (min-width:376px){.letter-spacing[data-v-0dbbd140]{letter-spacing:.2em}}#sub-title[data-v-0dbbd140]:after{position:absolute;content:\"\";background-color:red;width:30%;height:3px}#major[data-v-0dbbd140]{height:100vh}.gambar_prodi[data-v-0dbbd140]{width:100%;height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:cover}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
