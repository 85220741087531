<template>
  <div class=""></div>
  <div class="text-center d-flex align-items-center justify-content-center demo-wrap" id="">
    <div class="demo-content d-flex flex-column align-items-center justify-content-center bg-white py-4 px-3 mx-md-0 mx-3"
      style="border-radius: 20px;">
      <a href="https://satyaterrabhinneka.ac.id/profil/">
        <img src="@/assets/img/logo_navbar.png" alt="" style="max-height: 70px" />
      </a>
      <div>
        <i class="ri-close-circle-fill text-danger" style="font-size: 4rem"></i>
      </div>

      <div class="fs-flexible px-3">Mohon maaf <br> Form pendaftaran telah ditutup atau belum dibuka
      </div>
    </div>

    <!-- <p class="mb-4">
      Link aktivasi akun sudah dikirim ke email/Whatsapp Anda. Silahkan periksa
      email/Whatsapp Anda untuk melakukan pembayaran biaya pendaftaran
    </p>

    <button class="btn btn-light border border-success" @click="goPage('home')">
      <i class="ri-home-fill me-2"></i>Kembali Halaman Utama
    </button> -->
  </div>
</template>

<style scoped>
#report_register {
  /* height: calc(100vh - 46px) */
  /* max-height: 500px; */
  background-image: url("@/assets/img/PilihJalur.jpg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
}

.demo-wrap {
  position: relative;
  height: 100vh;
}

@media screen and (max-width: 576px) {
  .demo-wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url("@/assets/img/PilihJalur.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;

  }

  .fs-flexible {
    font-size: 1rem !important;
  }
}


@media screen and (min-width: 576px) {
  .demo-wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    background-image: url("@/assets/img/PilihJalur.jpg");
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: auto;

  }

  .fs-flexible {
    font-size: 1.25rem !important;
  }
}


.demo-content {
  position: relative;
}
</style>

<script>
import { goPage } from "@/assets/javascript/Function";

export default {
  data() {
    return {
      tahun_ajaran: "",
    }
  },
  methods: {
    goPage,
    index() {
      this.tahun_ajaran = localStorage.getItem('tahun_ajaran');
    }
  },
  mounted() {
    this.index();
  }
};
</script>
