import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "izitoast/dist/css/iziToast.min.css";
import { VueReCaptcha } from "vue-recaptcha-v3";

createApp(App)
  .use(router)
  .use(VueReCaptcha, {
    siteKey: "6LcTFs0jAAAAAIuASoj2Qs_fwxvdoN2FA3uiOlyd",
    loaderOptions: { autoHideBadge: false },
  })
  .mount("#app");
