<template>
  <NavbarDefault />
  <div class="pt-5 pt-lg-0">
    <section id="major">
      <div class="container-fluid h-100">
        <div class="text-start h-100">
          <div class="row h-100">
            <div class="col-12 col-sm-8 d-flex align-items-center">
              <div class="h-100 gambar_prodi"></div>
            </div>

            <div class="col-12 col-sm-4 d-flex mt-5 justify-content-center align-items-start align-items-sm-center">


              <div v-if="isloading" class="d-flex justify-content-center ">
                <loadingDotsView />
              </div>
              <div v-else class="w-75 mx-sm-5 text-center letter-spacing fw-bold ">
                <div>

                </div>
                <div class="mb-5 w-100 d-flex justify-content-center align-items-center ">
                  <a href="https://online.fliphtml5.com/tlfjk/ekaj/" target=”_blank”
                    class="btn fs-6 btn-outline-primary d-flex "><i class="ri-git-repository-line me-1"></i>E-Brosur</a>
                </div>

                <div @click="pilihJurusan(record.nama)" class="px-3 py-2 text-light mb-2 rounded-pill fs-6 "
                  style="cursor: pointer" v-bind:style="{ backgroundColor: record.warna }" v-for="record in records"
                  :key="record.id" value="">
                  {{ record.nama }}
                </div>
              </div>




            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section id="major" class="pt-5">
      <div class="d-block w-100">
        <div class="w-100 mb-3" style="height: calc(50vh - 66px)">
          <div class="major"></div>
        </div>
        <div class="w-100 bg-danger" style="height: calc(50vh - 66px)">
          test
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { goPage, base_url, AlertPopup, ErrorConnectionTimeOut } from "@/assets/javascript/Function";
import Cookies from "js-cookie";
import axios from "axios";
import NavbarDefault from "@/components/NavbarDefault.vue";
import loadingDotsView from "@/components/loadingDotsView.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      api: "",
      prodi: "form_prodi",
      fakultas: "form_fakultas",
      records: [],
      color: "black",

      isloading: true,
    };
  },
  name: "HomeView",
  components: { NavbarDefault, loadingDotsView },
  methods: {
    goPage,
    pilihJurusan(nama_jurusan) {
      if (nama_jurusan == "D3 KEBIDANAN") {
        Swal.fire({
          icon: "info",
          html:
            'APAKAH ANDA YAKIN? PROGRAM STUDI KEBIDANAN HANYA UNTUK <b>WANITA</b> ',
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Ya, lanjut proses',
          denyButtonText: `Tidak, tetap di halaman ini`,
          denyButtonColor: '#efefef',
          confirmButtonColor: '#dc3545',
          customClass: {
            denyButton: 'order-1',
            confirmButton: 'order-2',
          },
          didOpen: () => {
            const denyButton = Swal.getDenyButton();
            denyButton.style.color = 'black'; // Set your desired text color here
          }
        }).then((result) => {
          if (result.isConfirmed) {
            Cookies.set("nama_jurusan", nama_jurusan, 1);
            goPage("pilih_jalur");
          }
        })
      } else {
        Cookies.set("nama_jurusan", nama_jurusan, 1);
        goPage("pilih_jalur");
      }

    },
    index() {

      let formData = new FormData();
      formData.append("slug", "ustb");

      formData.append("url", this.prodi);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.prodi;
      } else {
        this.api = base_url + this.prodi;
      }
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;
            // console.log(response.data.data);
            this.isloading = false;
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tahun_ajaran() {
      const formData = new FormData();
      formData.append("slug", "ustb");
      formData.append("url", "form_ta");

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + "form_ta";
      } else {
        this.api = base_url + "form_ta";
      }
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            // this.currentRecord.tahun_ajaran = response.data.data[0].tahun_ajaran;
            // localStorage.setItem('tahun_ajaran', response.data.data[0].tahun_ajaran);

            // if (response.data.data[0].tanggal_mulai <= this.tanggal_acuan_hari_ini && this.tanggal_acuan_hari_ini <= response.data.data[0].tanggal_selesai) {
            //   //do nothing
            // } else {
            //   //do nothing
            //   goPage('form_pendaftaran_ditutup');
            // }
          }
          if (response.data.status == false) {
            goPage('form_pendaftaran_ditutup');
            // AlertPopup("error", response.data.message, "", false)
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
  },
  mounted() {
    this.tahun_ajaran();
    this.index();


    const badgeElement = document.querySelector('.grecaptcha-badge');
    // Check if the badge element exists before applying the style
    if (badgeElement) {
      badgeElement.style.visibility = 'hidden';
    }

  },
};
</script>

<style scoped>
.swal-popup {
  background: transparent !important;
  box-shadow: none !important;
}

.swal-content {
  background: transparent !important;
  border: none !important;
}

.swal-button {
  display: none !important;
}

@media screen and (max-width: 375px) {
  .letter-spacing {
    letter-spacing: 0.1em;
  }
}

@media screen and (min-width: 376px) {
  .letter-spacing {
    letter-spacing: 0.2em;
  }
}

#sub-title::after {
  position: absolute;
  content: "";
  background-color: red;
  width: 30%;
  height: 3px;
}

.row {}

#major {
  height: 100vh;
}

.gambar_prodi {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/img/major.jpg");
  background-position: center;
  background-size: cover;
}

/* .major {
  background-image: url("@/assets/img/major.png");
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
} */
</style>
