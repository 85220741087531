<template>
    <div class="container ">
        <div class="w-100 text-center py-3 ">
            <div class="d-flex justify-content-between align-items-center">
                <img src="@/assets/img/logo_navbar.png" alt="" width="200" class="mb-3">
                <img src="@/assets/img/BNI_logo.svg" alt="" width="100" height="30" class="mb-3">
            </div>
            <div class="text-secondary">Virtual Account</div>
            <div class=" fs-4  "> <span ref="textToCopy">{{ virtualAccount }}</span> <span @click="copyText"
                    style="cursor: pointer;"><i class="ri-file-copy-line text-secondary"></i></span>
            </div>
            <div class="">
                Harap dibayar sebelum : {{ this.tanggal_akhir_pembayaran }}
            </div>
            <button class="btn btn-success  mt-3" @click="cekPembayaran">
                <div class="d-flex ">
                    <i class="ri-bank-card-fill me-1"></i>
                    Cek Pembayaran
                </div>
            </button>

        </div>

        <div class="px-3 ">
            <div class=" fs-6 mt-5 mb-3">
                Tata cara pembayaran BNI Virtual Account (VA)
            </div>
            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dengan <b>ATM BNI</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Masukkan Kartu Anda.</li>
                            <li>Pilih Bahasa.</li>
                            <li>Masukkan PIN ATM Anda.</li>
                            <li>Pilih "Menu Lainnya".</li>
                            <li>Pilih "Transfer".</li>
                            <li>Pilih Jenis rekening yang akan Anda gunakan (Contoh; "Dari Rekening Tabungan").</li>
                            <li>Pilih "Virtual Account Billing".</li>
                            <li>Masukkan nomor Virtual Account Anda (contoh: {{ virtualAccount }}).</li>
                            <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</li>
                            <li>Konfirmasi, apabila telah sesuai, lanjutkan transaksi.</li>
                            <li>Transaksi Anda telah selesai.</li>
                        </ol>
                    </div>

                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dengan <b>mobile banking BNI</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password.</li>
                            <li>Pilih menu "Transfer".</li>
                            <li>Pilih menu "Virtual Account Billing" kemudian pilih rekening debet.</li>
                            <li>Masukkan nomor Virtual Account Anda (contoh: {{ virtualAccount }}) pada menu "input
                                baru".
                            </li>
                            <li>Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</li>
                            <li>Konfirmasi transaksi dan masukkan Password Transaksi.</li>
                            <li>Pembayaran Anda Telah Berhasil.</li>
                        </ol>

                    </div>

                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dengan <b>iBank Personal BNI</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Ketik alamat <a href="https://ibank.bni.co.id">https://ibank.bni.co.id</a> kemudian klik
                                "Enter".</li>
                            <li>Masukkan User ID dan Password.</li>
                            <li>Pilih menu "Transfer".</li>
                            <li>Pilih "Virtual Account Billing".</li>
                            <li>Kemudian masukan nomor Virtual Account Anda (contoh: {{ virtualAccount }}) yang hendak
                                dibayarkan. Lalu pilih rekening debet yang akan digunakan. Kemudian tekan "lanjut".</li>
                            <li>Kemudin tagihan yang harus dibayarkan akan muncul pada layar konfirmasi.</li>
                            <li>Masukkan Kode Otentikasi Token.</li>
                            <li>Pembayaran Anda telah berhasil.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dengan <b>BNI SMS Banking</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Buka aplikasi SMS Banking BNI.</li>
                            <li>Pilih menu Transfer.</li>
                            <li>Pilih menu Trf rekening BNI.</li>
                            <li>Masukkan nomor rekening tujuan dengan 16 digit Nomor Virtual Account (contoh:
                                {{ virtualAccount }}).</li>
                            <li>Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak
                                dapat diproses.</li>
                            <li>Pilih "Proses" kemudian "Setuju".</li>
                            <li>Reply sms dengan ketik pin sesuai perintah.</li>
                            <li>Transaksi Berhasil.</li>
                        </ol>
                        <p>Atau dapat juga langsung mengetik sms dengan format: TRF[SPASI]NomorVA[SPASI]NOMINAL dan
                            kemudian
                            kirim ke 3346 Contoh : TRF {{ virtualAccount }} 44000</p>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dari <b>Cabang atau Outlet BNI (Teller)</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Kunjungi Kantor Cabang/outlet BNI terdekat.</li>
                            <li>Informasikan kepada Teller, bahwa ingin melakukan pembayaran "Virtual Account Billing".
                            </li>
                            <li>Serahkan nomor Virtual Account Anda kepada Teller.</li>
                            <li>Teller melakukan konfirmasi kepada Anda.</li>
                            <li>Teller memproses Transaksi.</li>
                            <li>Apabila transaksi Sukses anda akan menerima bukti pembayaran dari Teller tersebut.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dari <b>Agen46</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Kunjungi Agen46 terdekat (warung/took/kios dengan tulisan Agen46).</li>
                            <li>Informasikan kepada Agen46, bahwa ingin melakukan pembayaran "Virtual Account Billing".
                            </li>
                            <li>Serahkan nomor Virtual Account Anda kepada Agen46.</li>
                            <li>Agen46 melakukan konfirmasi kepada Anda.</li>
                            <li>Agen46 Proses Transaksi.</li>
                            <li>Apabila transaksi Sukses anda akan menerima bukti pembayaran dari Agen46 tersebut.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dengan <b>ATM Bersama</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Masukkan kartu ke mesin ATM Bersama.</li>
                            <li>Pilih "Transaksi Lainnya".</li>
                            <li>Pilih menu "Transfer".</li>
                            <li>Pilih "Transfer ke Bank Lain".</li>
                            <li>Masukkan kode bank BNI (009) dan 16 Digit Nomor Virtual Account (contoh:
                                {{ virtualAccount }}).
                            </li>
                            <li>Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak
                                dapat diproses.</li>
                            <li>Konfirmasi rincian Anda akan tampil di layar, cek dan tekan 'Ya' untuk melanjutkan.</li>
                            <li>Transaksi Berhasil.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dari <b> Bank Lain</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Pilih menu "Transfer antar bank" atau "Transfer online antarbank".</li>
                            <li>Masukkan kode bank BNI (009) atau pilih bank yang dituju yaitu BNI.</li>
                            <li>Masukan 16 Digit Nomor Virtual Account pada kolom rekening tujuan, (contoh:
                                {{ virtualAccount }}).</li>
                            <li>Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak
                                dapat diproses.</li>
                            <li>Masukkan jumlah pembayaran : 44000.</li>
                            <li>Konfirmasi rincian Anda akan tampil di layar, cek dan apabila sudah sesuai silahkan
                                lanjutkan transaksi sampai dengan selesai.</li>
                            <li>Transaksi Berhasil.</li>
                        </ol>
                    </div>
                </div>
            </div>

            <div class="mb-3">
                <div>
                    <div class="border rounded-top px-3 py-2 bg-light">
                        Pembayaran BNI Virtual Account dari <b>OVO</b>
                    </div>
                    <div class="border px-3 py-2 rounded-bottom">
                        <ol>
                            <li>Buka aplikasi OVO.</li>
                            <li>Pilih menu Transfer.</li>
                            <li>Pilih "Rekening Bank".</li>
                            <li>Masukkan kode bank BNI (009) atau pilih bank yang dituju yaitu BNI.</li>
                            <li>Masukan 16 Digit Nomor Virtual Account pada kolom rekening tujuan, (contoh:
                                {{ virtualAccount }}).</li>
                            <li>Masukkan nominal transfer sesuai tagihan atau kewajiban Anda.</li>
                            <li>Pilih "Transfer".</li>
                            <li>Konfirmasi rincian Anda akan tampil di layar, cek dan apabila sudah sesuai silahkan
                                pilih
                                "Konfirmasi" untuk lanjutkan transaksi sampai dengan selesai</li>
                            <li>Transaksi Berhasil</li>
                        </ol>
                    </div>
                </div>
            </div>

        </div>
        <div class="mt-2 mb-3 text-center text-secondary">
            <!-- <i style="font-size: 8px!important;">powered by</i> <a href="https://arthaskyline.com"
                class="text-secondary"><img src="@/assets/img/icon_artha.png" alt="" width="50"></a>
            <div v-if="isMobile" style="font-size: 8px!important;" class="mt-2 mb-5">
                this site is protected by <a href="https://policies.google.com/privacy" target="_blank"> Google
                    Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service
                    apply</a>
            </div> -->

        </div>

    </div>
</template>

<script>

import {
    AlertPopup,
    ShowLoading,
    CloseLoading,
    ErrorConnectionTimeOut, base_url
    // base_url_header, ErrorConnectionTimeOut
} from "@/assets/javascript/Function";

import axios from "axios";
export default {
    data() {
        return {
            isMobile: false,
            virtualAccount: 0,
            nomor_invoice: "",
            tanggal_akhir_pembayaran: "",
        };
    },
    methods: {
        resizeHandler() {
            const screenWidth = window.innerWidth;
            if (screenWidth >= 576) {
                document.body.style.width = "100%";
            } else {
                document.body.style.width = "100%";
            }
        },
        copyText() {
            const textToCopy = this.$refs.textToCopy.innerText;
            const el = document.createElement('textarea');
            el.value = textToCopy;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            alert('Text copied to clipboard!');
        },
        SettingCaptchaView() {

            if (this.isMobile) {
                this.Captcha = "hidden"
            }

            const badgeElement = document.querySelector('.grecaptcha-badge');
            if (this)
                if (badgeElement) {

                    badgeElement.style.visibility = this.Captcha;
                    badgeElement.style.top = '150px';
                } else {
                    // Create a new MutationObserver
                    const observer = new MutationObserver(() => {
                        // When the reCAPTCHA badge element appears, hide it
                        const badgeElement = document.querySelector('.grecaptcha-badge');
                        if (badgeElement) {

                            badgeElement.style.visibility = this.Captcha;
                            badgeElement.style.top = '150px';

                            // Disconnect the observer once the badge element has been hidden
                            observer.disconnect();
                        }
                    });

                    // Start observing changes to the DOM
                    observer.observe(document.body, {
                        childList: true,
                        subtree: true,
                    });
                }
        },
        verifikasi(nomor_invoice) {
            ShowLoading();
            const formData = new FormData();

            formData.append("url", "invoice_index");
            formData.append("nomor_invoice", nomor_invoice);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "invoice_index";
            } else {
                this.api = base_url + "invoice_index";
            }

            // Add a new record to the API
            axios
                .post(this.api, formData)
                .then((response) => {
                    if (response.data.status == true) {

                        this.virtualAccount = response.data.data[0].reference;
                        this.tanggal_akhir_pembayaran = response.data.data[0].tanggal_terakhir_pembayaran;

                        const date = new Date(this.tanggal_akhir_pembayaran);
                        // Subtract one year from the date
                        date.setFullYear(date.getFullYear());



                        const formattedDate = date.toLocaleDateString("id-ID", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                        });

                        const formattedTime = date.toLocaleTimeString("id-ID", {
                            hour: "numeric",
                            minute: "numeric",
                        });

                        const result = `${formattedDate} ${formattedTime} WIB`;
                        this.tanggal_akhir_pembayaran = result;

                        // AlertPopup("success", "Silahkan Isi Data", "", 1500, false);
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);

                });
        },
        cekPembayaran() {
            const urlString = window.location.href;
            const cleanString = urlString.split('?')[0];
            const url = new URL(cleanString);
            url.pathname = "/";

            const modifiedString = url.toString();

            let callback = "";


            callback = "inquirybilling";

            ShowLoading();
            const formData = new FormData();
            // formData.append("url", "create_invoice_bni_demo");
            formData.append("kode", this.nomor_invoice);
            formData.append("slug", "ustb");
            formData.append("callback", callback);
            // formData.append("callback", "https://pmb.satyaterrabhinneka.ac.id/invoice_view?nomor_invoice=" + nomor_invoice);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + "create_invoice_bni_demo";
            } else {
                this.api = base_url + "create_invoice_bni";
            }
            // Add a new record to the API
            axios
                .post(this.api, formData)
                .then((response) => {

                    if (response.data.status == true) {
                        // AlertPopup("success", "Silahkan Isi Data", "", 1500, false);

                        CloseLoading();
                        window.location.href = modifiedString + "invoice_view?nomor_invoice=" + this.nomor_invoice;
                    }
                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                        if (response.data.data.payment_amount < response.data.data.trx_amount) {
                            AlertPopup("error", "", "Pembayaran belum diterima", 1500, false);
                        }
                        // CloseLoading();
                        // window.location.href = modifiedString + "invoice_view?nomor_invoice=" + this.nomor_invoice;
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);

                });

            // window.location.href = modifiedString + "invoice_view?nomor_invoice=" + this.nomor_invoice;
        }
    },

    mounted() {
        //proses convert ke format indonesia


        const userAgent = navigator.userAgent.toLowerCase();
        this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

        window.addEventListener("resize", this.resizeHandler);
        this.resizeHandler();

        this.SettingCaptchaView();
        const str = this.$route.fullPath;
        const slug = str.split('nomor_invoice=').pop();
        const slug2 = slug.split('&')[0];
        this.nomor_invoice = slug2;
        this.verifikasi(slug2);


    },
}
</script>