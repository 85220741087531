<template>
    <div class="loading-dots">
        <div class="dot bg-warning"></div>
        <div class="dot bg-primary"></div>
        <div class="dot bg-danger"></div>
        <div class="dot bg-success"></div>
    </div>
</template>

<style>
.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.dot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 0 4px;
    animation: fallingDots 1.2s infinite ease-in-out;
    animation-delay: 0.2s;
    /* Added initial delay before animation starts */
    animation-fill-mode: both;
    /* Added to maintain final state after animation */
}

.dot:nth-child(1) {
    animation-delay: 0s;
    /* Reset delay for the first dot */
}

.dot:nth-child(2) {
    animation-delay: 0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.2s;
}

.dot:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes fallingDots {
    0% {
        transform: translateY(-20px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(-20px);
    }
}
</style>
