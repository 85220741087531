<template>
  <div class="" id="">
    <section id=" home" class="d-flex justify-content-center fade">
      <div class="" id="img_home" @click="goPage('pilih_jurusan')">

      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { goPage, base_url, AlertPopup } from "@/assets/javascript/Function";
import Cookies from "js-cookie";
import axios from "axios";

export default {
  data() {
    return {
      prodi: "form_prodi",
      fakultas: "form_fakultas",
      records: [],
    };
  },
  name: "HomeView",
  components: {},
  methods: {
    goPage,
    pilihJurusan(nama_jurusan) {
      Cookies.set("nama_jurusan", nama_jurusan, 1);
      goPage("pilih_jalur");
    },
    index() {
      let formData = new FormData();
      formData.append("slug", "ustb");
      axios
        .post(base_url + this.prodi, formData)
        .then((response) => {
          if (response.data.status == true) {
            this.records = response.data.data;
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pindah_dalam_3detik() {
      setTimeout(() => {
        goPage('pilih_jurusan');
      }, 3000);

    }


  },
  mounted() {
    this.index();

    const badgeElement = document.querySelector('.grecaptcha-badge');
    // Check if the badge element exists before applying the style
    if (badgeElement) {
      badgeElement.style.visibility = 'hidden';
    }
    this.pindah_dalam_3detik();
  },
  created() {
    const badgeElement = document.querySelector('.grecaptcha-badge');
    // Check if the badge element exists before applying the style
    if (badgeElement) {
      badgeElement.style.visibility = 'hidden';
    }
  }


};

</script>

<style scoped>
@media screen and (max-width: 375px) {
  #img_home {
    width: 100vw;
    height: 100vh;
    background-image: url("@/assets/img/home_mobile.jpg");
    background-position: center;
    background-size: cover;
  }
}

@media screen and (min-width: 375px) {
  #img_home {
    width: 100vw;
    height: 100vh;
    background-image: url("@/assets/img/home_tab.png");
    background-position: center;
    background-size: cover;
  }
}

@media screen and (min-width: 576px) {
  #img_home {
    height: 100vh;
    width: 100%;
    background-image: url("@/assets/img/Home.png");
    background-position: center;
    background-size: cover;
  }
}

@media screen and (min-width: 768px) {
  #img_home {
    height: 100vh;
    width: 100%;
    background-image: url("@/assets/img/Home.png");
    background-position: center;
    background-size: cover;
  }
}

@media screen and (min-width: 1024px) {
  #img_home {
    height: 100vh;
    width: 100%;
    background-image: url("@/assets/img/Home.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }


}

#sub-title::after {
  position: absolute;
  content: "";
  background-color: red;
  width: 30%;
  height: 3px;
}

.grecaptcha-badge {
  visibility: hidden;
}



.fade {
  animation: fadeInAnimation ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: translate(0, -10vh);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
</style>
