import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PilihJalurView from "../views/PilihJalurView.vue";
import FormPendaftaranView from "../views/FormPendaftaranView.vue";
import FormVerifikasiView from "../views/FromVerifikasiView.vue";
import FormDataAkunView from "../views/FormDataAkunView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import ReportRegister from "../views/ReportRegister.vue";
import ReportTest from "../views/ReportTest.vue";
import TestTertulisView from "../views/TestTertulisView.vue";
import PilihJurusanView from "../views/PilihJurusanView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import CaraPembayaranView from "../views/CaraPembayaranView.vue";
import loadingDotsView from "../components/loadingDotsView.vue";
import FormPendaftaranDitutupView from "../views/FormPendaftaranDitutup.vue";
import FormLengkapiDataView from "../views/FormLengkapiDataView.vue";
import FormLengkapiDataKipView from "../views/FormLengkapiDataViewKip.vue";
import FormLengkapiDataScholarshipView from "../views/FormLengkapiDataViewScholarship.vue";
import InvoiceViewDemo from "@/views/InvoiceViewDemo.vue";

//pengaturan

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/home",
    name: "home2",
    component: HomeView,
  },
  {
    path: "/pilih_jalur",
    name: "PilihJalur",
    component: PilihJalurView,
  },
  {
    path: "/pilih_jurusan",
    name: "PilihJurusan",
    component: PilihJurusanView,
  },

  {
    path: "/form_pendaftaran",
    name: "formpendaftaran",
    component: FormPendaftaranView,
  },
  {
    path: "/form_verifikasi",
    name: "formverifikasi",
    component: FormVerifikasiView,
  },
  {
    path: "/data_akun",
    name: "formdataakun",
    component: FormDataAkunView,
  },
  {
    path: "/report_register",
    name: "reportregister",
    component: ReportRegister,
  },
  {
    path: "/report_test",
    name: "reporttest",
    component: ReportTest,
  },

  {
    path: "/test_tertulis",
    name: "test_tertulis",
    component: TestTertulisView,
  },
  {
    path: "/invoice_view",
    name: "invoice_view",
    component: InvoiceView,
  },
  {
    path: "/invoice_view_demo",
    name: "invoice_view_demo",
    component: InvoiceViewDemo,
  },
  {
    path: "/cara_pembayaran",
    name: "cara_pembayaran",
    component: CaraPembayaranView,
  },
  {
    path: "/form_pendaftaran_ditutup",
    name: "form_pendaftaran_ditutup",
    component: FormPendaftaranDitutupView,
  },
  {
    path: "/form_lengkapi_data",
    name: "form_lengkapi_data",
    component: FormLengkapiDataView,
  },
  {
    path: "/form_lengkapi_data_kip",
    name: "form_lengkapi_data_kip",
    component: FormLengkapiDataKipView,
  },
  {
    path: "/form_lengkapi_data_scholarship",
    name: "form_lengkapi_data_scholarship",
    component: FormLengkapiDataScholarshipView,
  },
  {
    path: "/test",
    name: "test",
    component: loadingDotsView,
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];
const routerHistory = createWebHistory();
const router = createRouter({
  history: routerHistory,
  mode: "history",
  base: "/",
  routes,
});

export default router;
